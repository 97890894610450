import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import ContactInfo from "../../../components/contact-info/contact-info";
import DebitCardsOpenAccount from "../../../components/sign-up/debit-cards-open-account";
import ServiceStatesOpenAccountSection from "../../../components/service-states/service-states-open-account-section";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import ProductComparisonTable from "../../../components/product-comparison-table/product-comparison-table";
import FeaturesSection from "../../../components/rewards-checking/features-section";
import StartSavingsSection from "../../../components/start-savings-section/start-savings-section";

const RewardsChecking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/rewards-checking/hero-rewards-checking-040424-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const title = "Cuentas corrientes de recompensas: compare y abra en línea",
    description =
      "Conozca los diferentes tipos de cuentas corrientes de recompensas en WaFd Bank, incluida nuestra cuenta corriente Premium Rewards que incluye descuentos en viajes.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/rewards-checking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-rewards-checking-040424.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "rewards-checking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman sitting on a car with arms raised in celebration, looking at beautiful mountain scenery.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Obtenga Las Recompensas Que Se Merece"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn",
            text: "Abrir una cuenta",
            url: "/open-an-account",
            class: "btn-white",
            containerClass: "mb-3"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Rewards y Premium Rewards Checking"
    }
  ];

  const debitCardData = {
    sectionId: "rewards-checking-debit-cards-section",
    titleText: "Abramos su cuenta hoy",
    depositText: "Sólo $25 de depósito inicial."
  };

  const videoData = {
    vimeoId: "984569436",
    videoTitle: "Rewards Checking Account Perks at WaFd Bank",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const comparisonData = [
    {
      id: "rewards-checking",
      title: "Rewards Checking",
      values: {
        initialDeposit: true,
        serviceCharge: `<strong class="text-success">$6 al mes</strong>`,
        gallonGasSaving: true,
        shoppingDiscounts: true,
        travelDiscounts: true,
        mobileProtection: `Hasta <span class="text-success font-weight-bold">$500</span> por reclamo, <span class="text-success font-weight-bold">$1,000</span> por año`,
        identityFraud: `Hasta <span class="text-success font-weight-bold">$25,000</span>`,
        creditMonitoring: true,
        healthDiscounts: true,
        buyerProtection: true,
        accidentalDeath: true
      }
    },
    {
      id: "premium-rewards-checking",
      title: "Premium Rewards Checking",
      values: {
        initialDeposit: true,
        serviceCharge: `<strong class="text-success">$9 al mes</strong>`,
        gallonGasSaving: true,
        shoppingDiscounts: true,
        darkWebMonitoring: true,
        travelDiscounts: true,
        mobileProtection: `Hasta <span class="text-success font-weight-bold">$800</span> por reclamo, <span class="text-success font-weight-bold">$1,000</span> por año`,
        identityFraud: `Hasta <span class="text-success font-weight-bold">$1,000,000</span>`,
        identityTheft: true,
        creditMonitoring: true,
        healthDiscounts: true,
        telehealthServices: true,
        roadsideAssitance: true,
        creditScoreSimulator: true,
        buyerProtection: true,
        accidentalDeath: true,
        rideshareProtection: true
      }
    }
  ];

  const tableData = {
    title: "Beneficios y Recompensas de la Cuenta",
    rows: [
      {
        id: "initial-deposit",
        title: "Solo $25 de depósito inicial"
      },
      {
        id: "service-charge",
        title: "Cargo por servicio"
      },
      {
        id: "gallon-gas-saving",
        title: "Ahorro de 5¢ por Galón de Gasolina"
      },
      {
        id: "shopping-discounts",
        title: "Descuentos para Compras"
      },
      {
        id: "dark-web-monitoring",
        title: "Monitoreo de Dark Web"
      },
      {
        id: "travel-discounts",
        title: "Descuentos para Viajes y Esparcimiento"
      },
      {
        id: "mobile-protection",
        title: "Protección del Teléfono Celular"
      },
      {
        id: "identity-fraud",
        title: "Cobertura de Reembolso de Gastos por Fraude de Identidad"
      },
      {
        id: "identity-theft",
        title: "Servicios de Resolución de Robo de Identidad"
      },
      {
        id: "credit-monitoring",
        title: "Monitoreo de Crédito"
      },
      {
        id: "health-discounts",
        title: "Descuentos para Salud"
      },
      {
        id: "telehealth-services",
        title: "Servicios de Telesalud"
      },
      {
        id: "roadside-assitance",
        title: "Asistencia de Carretera 24/7"
      },
      {
        id: "credit-score-simulator",
        title: "Simulador de Credit Score"
      },
      {
        id: "buyer-protection",
        title: "Protección al Comprador y Garantía Extendida"
      },
      {
        id: "accidental-death",
        title: "Seguro de Muerte Accidental y Desmembramiento"
      },
      {
        id: "rideshare-protection",
        title: "Protección de Viajes Compartidos"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Rewards y Premium Rewards Checking</h1>
        <div className="row">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <VimeoVideoFrame {...videoData} />
          </div>
          <div className="col-12 col-md-6">
            <h4 className="mb-0">
              Mejore su cuenta con Rewards o Premium Rewards Checking de WaFd Bank. Disfruta de una cuenta bancaria
              segura que ofrece numerosos beneficios en las cosas más importantes de la vida, como descuentos para
              gasolina, protección y resolución de robo de identidad, más descuentos para compras, viajes y
              esparcimiento. ¡Obtén la cuenta que hace más por ti!
            </h4>
          </div>
        </div>
      </section>
      <ProductComparisonTable comparisonData={comparisonData} tableData={tableData} />
      <DebitCardsOpenAccount {...debitCardData} />
      <FeaturesSection />
      <StartSavingsSection isGray={true} />
      <ServiceStatesOpenAccountSection containerClass="bg-white" />
      <ContactInfo isSpanish={true} />
      <section className="container">
        <p className="text-gray-60 text-sm" id="interest-rewards-checking-disclaimer-1">
          Los beneficios están disponibles para los propietarios de cuentas de cheques personales y sus propietarios
          conjuntos de cuentas sujetos a los términos y condiciones de los Beneficios aplicables. Algunos Beneficios
          requieren autenticación, registro y/o activación. Los beneficios no están disponibles para un "firmante" de la
          cuenta que no sea propietario de la cuenta ni para empresas, clubs, fideicomisos, organizaciones y/o iglesias
          y sus miembros, o escuelas y sus empleados/estudiantes. Pueden aplicarse restricciones. Para obtener detalles
          adicionales, acceda a su beneficio de Ahorro de Gas a través de su banca en línea de WaFd Bank después del 20
          de Mayo de 2024.
        </p>
        <p className="text-gray-60 text-sm" id="interest-rewards-checking-disclaimer-2">
          Notas del programa especial: Las descripciones aquí contenidas son solo resúmenes y no incluyen todos los
          términos, condiciones y exclusiones de los Beneficios descritos. Consulte la Guía de beneficios y/o los
          documentos del seguro para obtener detalles completos sobre la cobertura y las exclusiones. La cobertura se
          proporciona a través de la empresa mencionada en la Guía de beneficios o en el certificado de seguro. La guía
          de beneficios y los documentos del seguro se pueden encontrar a través de su banca en línea WaFd o la
          aplicación móvil de WaFd Bank después del 20 de mayo de 2024. Si desea nombrar un beneficiario para su
          cobertura de seguro por muerte accidental y desmembramiento, complete un formulario de beneficiario. Si no hay
          ningún beneficiario designado registrado, los beneficios se pagarán de acuerdo con la Póliza Maestra. Como
          propietario de una cuenta elegible, está inscrito como miembro de la Asociación Econocheck, de la cual su
          institución financiera es patrocinadora. Para obtener más información, llame al{" "}
          <a href="tel:866-210-0361" id="benefits-customer-service-tel-link">
            866-210-0361
          </a>
          . Los productos de seguro no están asegurados por la FDIC ni por ninguna agencia del gobierno federal; no es
          un depósito ni está garantizado por el banco ni por ninguna filial bancaria. Disponible para el titular de la
          cuenta y su cónyuge/pareja de hecho y hasta seis (6) hijos dependientes de 2 años o más. Esto no es un seguro.
          Credit Score es un VantageScore 3.0 basado en datos de una sola oficina. Los terceros pueden utilizar un
          VantageScore diferente o un tipo diferente de puntaje crediticio para evaluar su solvencia crediticia. Una vez
          que haya activado el monitoreo de archivos crediticios, podrá solicitar su puntaje crediticio. Una vez que lo
          hayas hecho, tendrás acceso a tu puntuación mensualmente. Los ahorros con descuento para la salud NO son un
          seguro. Se requiere registro/activación. Shopping Rewards requiere registro/activación; Disponible únicamente
          a través de dispositivos móviles y web. Los descuentos en viajes y ocio están disponibles únicamente a través
          de dispositivos móviles y web.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default RewardsChecking;
